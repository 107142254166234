import * as React from "react";

import { useTranslation } from "react-i18next";
import NumberComponent from "../form-controls/number-component";
import { LOAN_INTEREST_MAX, LOAN_INTEREST_STEP } from "../../defaults";
import { userFormattedPercent } from "../../utils/format";
import type { ILoanFieldProp } from "./loan-field-base";

export default function LoanInterestField(props: ILoanFieldProp) {
    const { t } = useTranslation();
    const { loan, onChange, onValueSet } = props;

    const { interest } = loan;

    return (
        <NumberComponent
            label={t("loan:interest")}
            unit="%"
            ariaLabel={t("common:percentage")}
            value={userFormattedPercent(interest)}
            hint={t("hint:loan-interest")}
            max={LOAN_INTEREST_MAX}
            step={LOAN_INTEREST_STEP}
            decimal={true}
            name="loan-interest"
            onChange={onChange}
            onValueSet={onValueSet}
        />
    );
}
