import * as React from "react";
import Maintenance from "../fields/maintenance-field";
import Fee from "../fields/fee-field";

export default function RentalFieldsComponent() {
    return (
        <div className="row">
            <div className="hint-container container">
                <Fee />
            </div>
            <div className="hint-container container">
                <Maintenance />
            </div>
            <div className="hint-container container" />
        </div>
    );
}
