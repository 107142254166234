import config from "react-global-configuration";

import {
    CAPITAL_GAIN_TAX_RATE,
    DEED_RATE,
    DOWNPAYMENT_RATE,
    GROSS_HOUSEHOLD_INCOME,
    HOUSING_DEED_RATE,
    LOAN_TO_INCOME_THRESHOLD,
    MIN_CAPITAL_RATIOS,
    MIN_INCOME,
    MONTHS_IN_YEAR,
} from "../defaults";
import { HousingTypes, type IHousing } from "../models/housing";
import { getTotalHousingCost } from "../selectors/housings";
import { getTotalLoanAmount, getTotalLoanInstallment } from "../selectors/loans";
import { monthlyInterestCostAfterDeduction } from "./loans";

export function computeHouseholdIncome(price?: number) {
    if (price) {
        const downpaymentRate = config.get<number>("defaultDownpaymentRate", DOWNPAYMENT_RATE);
        const calculatedIncome = (price - price * downpaymentRate) / (MONTHS_IN_YEAR * LOAN_TO_INCOME_THRESHOLD);
        const incomeRoundedUpToThousands = Math.ceil(calculatedIncome / 1000) * 1000;
        return Math.max(incomeRoundedUpToThousands, MIN_INCOME);
    }

    return GROSS_HOUSEHOLD_INCOME;
}

export function compareTotalHousingCost(prospect: IHousing, existing: IHousing): number {
    const totalProspectCost = getTotalHousingCost(prospect);
    const totalExistingCost = getTotalHousingCost(existing);

    return totalProspectCost - totalExistingCost;
}

export function compareCapital(prospect: IHousing, existing: IHousing): number {
    if (existing.type === HousingTypes.Rental) return null;

    const capitalProspectHousing = calculateHousingCapital(prospect);

    let capitalExistingHousing = calculateHousingCapital(existing);

    if (existing.calculateCapitalGainTax) {
        capitalExistingHousing = capitalExistingHousing - calculateCapitalGainTax(existing);
    }

    return capitalProspectHousing - capitalExistingHousing;
}

export function compareLoan(prospect: IHousing, existing: IHousing): number {
    return getTotalLoanAmount(prospect) - getTotalLoanAmount(existing);
}

export function calculateHousingCapital(housing: IHousing): number {
    if (!housing) {
        return 0;
    }

    const { price = 0 } = housing;
    const loans = getTotalLoanAmount(housing);
    return Math.round(price - loans);
}

export function calculateMinimumCapital(price: number, isProspect = true): number {
    return isProspect ? Math.round(MIN_CAPITAL_RATIOS.Prospect * price) : MIN_CAPITAL_RATIOS.Existing;
}

export function calculateCapitalGainTax(housing: IHousing): number {
    if (!housing || housing.type === HousingTypes.Rental) {
        return 0;
    }

    const { type, price, originalPrice, renovationCost } = housing;
    let housingCosts = originalPrice + renovationCost;

    if (type === HousingTypes.House) {
        housingCosts += originalPrice * HOUSING_DEED_RATE;
    }

    const tax = Math.round((price - housingCosts) * CAPITAL_GAIN_TAX_RATE);
    return Math.max(tax, 0);
}

interface ITotalHousingCostInput {
    type: string;
    fee: number;
    interestCost: number;
    maintenance: number;
    installment: number;
}

export function totalHousingCost({ type, fee, interestCost, maintenance, installment }: ITotalHousingCostInput): number {
    if (type === HousingTypes.Condominium) return Math.round((fee ?? 0) + interestCost + (maintenance ?? 0) + installment);

    if (type === HousingTypes.House) return Math.round(interestCost + (maintenance ?? 0) + installment);

    if (type === HousingTypes.Rental) return Math.round((fee ?? 0) + (maintenance ?? 0));

    return 0;
}

export function calculateDeed(type, price) {
    return type !== HousingTypes.Lease ? price * HOUSING_DEED_RATE : null;
}

export function calculateMortgageDeedFee(loanAmount, deedAmount) {
    if (!Number.isInteger(deedAmount)) return;

    const loanAmountToCoverWithNewDeed = Math.max(loanAmount - deedAmount, 0);
    const deedFee = Math.round(loanAmountToCoverWithNewDeed * DEED_RATE);

    return Math.floor(deedFee / 1000) * 1000;
}

export function calculateTotalHousingCost(housing: IHousing): number {
    const monthlyInterest = monthlyInterestCostAfterDeduction(housing.loans);
    const installment = getTotalLoanInstallment(housing);

    if (!housing) {
        return 0;
    }

    return totalHousingCost({
        type: housing.type,
        fee: housing.fee,
        maintenance: housing.maintenance,
        interestCost: monthlyInterest,
        installment,
    });
}

export function calculateLoanToValue(housing: IHousing) {
    const { price } = housing;
    if (price == null) return 0;

    const loan = getTotalLoanAmount(housing);
    return loan / price;
}
