export const MONTHS_IN_YEAR = 12;

export const HOUSEHOLD_INCOME_MAX = 200000;
export const HOUSEHOLD_INCOME_STEP = 1000;
export const GROSS_HOUSEHOLD_INCOME = 62000;

export const CAPITAL_STEP = 50000;
export const DOWN_PAYMENT_NR_STEP_15_TO_100 = 85;

export const LOAN_AMOUNT_STEP = 50000;
export const LOAN_INTEREST_STEP = 0.05;
export const LOAN_INTEREST_MAX = 10;
export const LOAN_INSTALLMENT_STEP = 500;
export const LOAN_INTEREST = 0.03;
export const LOAN_INSTALLMENT = 2000;

export const FEE_STEP = 100;
export const FEE_MAX = 20000;

export const MAINTENANCE_STEP = 100;
export const MAINTENANCE_MAX = 10000;

export const PRICE_STEP = 50000;
export const PRICE_MIN = 0;
export const PRICE_MAX = 10000000;

export const RENOVATION_COST_STEP = 50000;
export const RENOVATION_COST_MAX = 1000000;

export const HOUSING_DEED_RATE = 0.015;
export const CAPITAL_GAIN_TAX_RATE = 0.22;

export enum MIN_CAPITAL_RATIOS {
    Prospect = 0.15,
    Existing = 0,
}

export const SAVINGS_PAYMENT_AMOUNT_PRICE_FACTOR = 0.1;
export const SAVINGS_PAYMENT_AMOUNT_STEP = 50000;
export const SAVINGS_PAYMENT_AMOUNT_MIN = 0;

export const SAVINGS_ACCOUNT_AMOUNT_PRICE_FACTOR = 0.01;
export const SAVINGS_ACCOUNT_AMOUNT_STEP = 50000;
export const SAVINGS_ACCOUNT_AMOUNT_MIN = 0;

export const EXISTING_HOUSING_PRICE = 3000000;
export const EXISTING_HOUSING_FEE = 4000;
export const EXISTING_HOUSING_NON_HOUSE_MAINTAINANCE = 700;
export const EXISTING_HOUSING_HOUSE_MAINTAINANCE = 4200;
export const EXISTING_LTV_RATIO = 0.67;

export const DEDUCTION_THRESHOLD = 100000;
export const BASE_DEDUCTION_RATE = 0.3;
export const EXTRA_DEDUCTION_RATE = 0.21;

export const DOWNPAYMENT_RATE = 0.15;

export const MIN_INCOME = 25000;

export const DEED_RATE = 0.02;

export const LOAN_TO_INCOME_THRESHOLD = 4.5;

export const HIGHEST_LOAN_TO_VALUE_THRESHOLD = 0.85;
export const HIGH_LOAN_TO_VALUE_THRESHOLD = 0.7;
export const LOW_LOAN_TO_VALUE_THRESHOLD = 0.5;

export const LTV_THRESHOLDS = [HIGHEST_LOAN_TO_VALUE_THRESHOLD, HIGH_LOAN_TO_VALUE_THRESHOLD, LOW_LOAN_TO_VALUE_THRESHOLD];

export const HIGH_LOAN_TO_VALUE_FACTOR = 0.02;
export const LOW_LOAN_TO_VALUE_INSTALLMENT_FACTOR = 0.01;
export const LOW_LOAN_TO_INCOME_FACTOR = 0.01;
