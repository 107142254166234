import * as React from "react";
import { useTranslation } from "react-i18next";
import config from "react-global-configuration";
import { ModuleStore } from "../../utils/module-store";

export default function InsightComponent() {
    const { t } = useTranslation();

    const insightsConfig = config.get("insights");
    if (insightsConfig === false) return <></>;

    const defaultInsights = [{ id: "future-installment" }, { id: "future-amortization" }, { id: "amortization-requirement" }];

    const insights = insightsConfig === true ? defaultInsights : insightsConfig || defaultInsights;

    const selectComponent = (insight) => {
        const module = ModuleStore.getInsightsModule(insight.id);
        if (!module) {
            return null;
        }

        return React.createElement(module.component, {
            configuration: insight.configuration,
        });
    };

    return (
        <React.Fragment>
            <div>
                <h4>{t("insight:header")}</h4>
            </div>
            <div className="row">
                {insights.map((insight) => (
                    <div key={insight.id} className="container fill shrink">
                        {selectComponent(insight)}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
}
