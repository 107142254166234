import type { Moment } from "moment";
import { SAVINGS_ACCOUNT_AMOUNT_PRICE_FACTOR, SAVINGS_PAYMENT_AMOUNT_PRICE_FACTOR } from "../defaults";

export interface ISavings {
    paymentAmount: number;
    accountAmount: number;
    paymentDate: Moment;
}

export enum PaymentSteps {
    AdvanceAgreement = "advance-agreement",
    LeaseAgreement = "lease-agreement",
    Entry = "entry",
}

export interface IPaymentStep {
    id: string;
    date: Moment;
    amount: number;
    percentage: number;
}

export function createSavings(price: number): ISavings {
    return {
        paymentAmount: price * SAVINGS_PAYMENT_AMOUNT_PRICE_FACTOR,
        accountAmount: price * SAVINGS_ACCOUNT_AMOUNT_PRICE_FACTOR,
        paymentDate: null,
    };
}
