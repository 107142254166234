export function shuffleArray<T>(array: Array<T>): Array<T> {
    // Fisher-Yates (aka Knuth) Shuffle
    let currentIndex = array.length;
    let temporaryValue: T;
    let randomIndex: number;

    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export interface IWeighted {
    [key: string]: number;
}

export function weightedPick(spec: IWeighted) {
    const table = [];
    for (const i of Object.keys(spec)) {
        // The constant 10 below should be computed based on the
        // weights in the spec for a correct and optimal table size.
        // E.g. the spec {0:0.999, 1:0.001} will break this impl.
        for (let j = 0; j < spec[i] * 10; j++) {
            table.push(i);
        }
    }
    return () => {
        return table[Math.floor(Math.random() * table.length)];
    };
}
