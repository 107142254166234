import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import type { IRootState } from "../../../reducers/root-reducer";
import { getFutureInstallment } from "../../../selectors/loans";
import { toCurrency } from "../../../utils/format";
import { insightsModule } from "../../../utils/module-store";

const FutureInstallmentInsight = () => {
    const { t } = useTranslation();
    const { installment } = useSelector((state: IRootState) => {
        return {
            installment: getFutureInstallment(state.prospect, 10),
        };
    });

    const insight =
        installment > 0
            ? t("insight:future-installment", {
                  installment: `<strong>${toCurrency(installment)}</strong>`,
              })
            : t("insight:no-installment");

    return (
        <p
            data-cy="insight-future-installment"
            dangerouslySetInnerHTML={{
                __html: insight,
            }}
        />
    );
};

export default insightsModule("future-installment")(FutureInstallmentInsight);
