import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { calculateDownpayment, installmentThresholds, requiredMinInstallment, requiredMinInstallmentRate } from "../../../functions/installment";
import type { IRootState } from "../../../reducers/root-reducer";
import { toCurrency, userFormattedPercent } from "../../../utils/format";
import { insightsModule } from "../../../utils/module-store";

const AmortizationRequirementInsight = () => {
    const { t } = useTranslation();
    const { price, income } = useSelector((state: IRootState) => {
        return {
            price: state.prospect.price,
            income: state.income,
        };
    });

    const thresholds = installmentThresholds(price, income);

    return (
        <div>
            <p>{t("insight:amortization-requirements-header")}</p>
            <table data-cy="insight-amortization-table">
                <thead>
                    <tr>
                        <th>{t("prospect:capital")}</th>
                        <th>{t("common:amortization")}</th>
                    </tr>
                </thead>
                <tbody>
                    {thresholds.map((loanToValueFactor) => {
                        const downpayment = calculateDownpayment(price, loanToValueFactor);
                        const installment = requiredMinInstallment(price, loanToValueFactor, income);
                        const minRate = requiredMinInstallmentRate(price, loanToValueFactor, income);

                        const ratio = userFormattedPercent(1 - loanToValueFactor);

                        return (
                            <tr key={loanToValueFactor}>
                                <td data-cy={`insight-capital-${ratio}`}>
                                    {toCurrency(downpayment)} ({ratio} %)
                                </td>
                                <td data-cy={`insight-amortization-${userFormattedPercent(minRate)}`}>
                                    {toCurrency(installment)}/mån ({userFormattedPercent(minRate)} %){" "}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default insightsModule("amortization-requirement")(AmortizationRequirementInsight);
