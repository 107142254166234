import { createReducer, createAction } from "typesafe-actions";
import type { HousingTypes, IHousing } from "../models/housing";
import { updateMinInstallment } from "../functions/loans";
import {
    setProspectCapitalFn,
    setProspectEstimatedPriceFn,
    setProspectLoanAmountFn,
    setProspectLoanInstallmentFn,
    setProspectLoanInterestFn,
} from "../actions/prospect-actions";
import {
    addExistingLoanFn,
    removeExistingLoanFn,
    setExistingCalculateCapitalGainTaxFn,
    setExistingFeeFn,
    setExistingLoanAmountFn,
    setExistingLoanInstallmentFn,
    setExistingLoanInterestFn,
    setExistingMaintenanceFn,
    setExistingOriginalPriceFn,
    setExistingPriceFn,
    setExistingRenovationCostFn,
    setExistingTypeFn,
} from "../actions/existing-actions";
import type { ArrayValue } from "../models/loan";
import type { IPaymentStep, ISavings } from "../models/savings";
import { setSavingsAccountAmountFn, setSavingsPaymentAmountFn, setSavingsPaymentDateFn } from "../actions/savings-actions";

export function clone(state: IRootState) {
    return { ...state };
}

export interface IRootState {
    prospect: IHousing;
    income?: number;
    existing?: IHousing;
    savings?: ISavings;
}

export function setIncomeFn(state: IRootState, action) {
    const newState = clone(state);
    newState.income = action.payload;

    newState.prospect = updateMinInstallment(newState.prospect, newState.income);

    return newState;
}

export const setIncome = createAction("root/SETINCOME")<number>();

export const setProspectEstimatedPrice = createAction("prospect/SETESTIMATEDPRICE")<number>();

export const setProspectLoanAmount = createAction("prospect/SETLOANAMOUNT")<number>();

export const setProspectLoanInterest = createAction("prospect/SETLOANINTEREST")<number>();

export const setProspectLoanInstallment = createAction("prospect/SETLOANINSTALLMENT")<number>();

export const setProspectCapital = createAction("prospect/SETCAPITAL")<number>();

export const setExistingType = createAction("existing/SETTYPE")<HousingTypes>();

export const setExistingFee = createAction("existing/SETFEE")<number>();

export const setExistingPrice = createAction("existing/SETPRICE")<number>();

export const setExistingMaintenance = createAction("existing/SETMAINTENANCE")<number>();

export const setExistingCalculateCapitalGainTax = createAction("existing/SETCALCULATECAPITALGAINTAX")<boolean>();

export const setExistingOriginalPrice = createAction("existing/SETORIGINALPRICE")<number>();

export const setExistingRenovationCost = createAction("existing/SETRENOVATIONCOST")<number>();

export const setExistingLoanAmount = createAction("existing/SETLOANAMOUNT")<ArrayValue>();

export const setExistingLoanInterest = createAction("existing/SETLOANINTEREST")<ArrayValue>();

export const setExistingLoanInstallment = createAction("existing/SETLOANINSTALLMENT")<ArrayValue>();

export const addExistingLoan = createAction("existing/ADDLOAN")();

export const removeExistingLoan = createAction("existing/REMOVELOAN")<number>();

export const setSavingsPaymentDate = createAction("savings/SETPAYMENTDATE")<IPaymentStep>();

export const setSavingsPaymentAmount = createAction("savings/SETPAYMENTAMOUNT")<number>();

export const setSavingsAccountAmount = createAction("savings/SETACCOUNTAMOUNT")<number>();

export const rootReducer = createReducer({} as IRootState)
    .handleAction(setProspectEstimatedPrice, setProspectEstimatedPriceFn)
    .handleAction(setIncome, setIncomeFn)
    .handleAction(setProspectCapital, setProspectCapitalFn)
    .handleAction(setProspectLoanAmount, setProspectLoanAmountFn)
    .handleAction(setProspectLoanInterest, setProspectLoanInterestFn)
    .handleAction(setProspectLoanInstallment, setProspectLoanInstallmentFn)
    .handleAction(setExistingType, setExistingTypeFn)
    .handleAction(setExistingFee, setExistingFeeFn)
    .handleAction(setExistingMaintenance, setExistingMaintenanceFn)
    .handleAction(setExistingCalculateCapitalGainTax, setExistingCalculateCapitalGainTaxFn)
    .handleAction(setExistingPrice, setExistingPriceFn)
    .handleAction(setExistingOriginalPrice, setExistingOriginalPriceFn)
    .handleAction(setExistingRenovationCost, setExistingRenovationCostFn)
    .handleAction(setExistingLoanAmount, setExistingLoanAmountFn)
    .handleAction(setExistingLoanInterest, setExistingLoanInterestFn)
    .handleAction(setExistingLoanInstallment, setExistingLoanInstallmentFn)
    .handleAction(addExistingLoan, addExistingLoanFn)
    .handleAction(removeExistingLoan, removeExistingLoanFn)
    .handleAction(setSavingsPaymentDate, setSavingsPaymentDateFn)
    .handleAction(setSavingsPaymentAmount, setSavingsPaymentAmountFn)
    .handleAction(setSavingsAccountAmount, setSavingsAccountAmountFn);
