import * as React from "react";

import { useTranslation } from "react-i18next";
import NumberComponent from "../form-controls/number-component";
import { LOAN_AMOUNT_STEP } from "../../defaults";
import type { ILoanFieldProp } from "./loan-field-base";

export default function LoanAmountField(props: ILoanFieldProp) {
    const { t } = useTranslation();
    const { housing, loan, onChange, onValueSet, className } = props;

    const { amount } = loan;
    const { price } = housing;

    return (
        <NumberComponent
            label={t("loan:amount")}
            className={className}
            unit="kr"
            ariaLabel={t("common:currency")}
            value={amount}
            hint={t("hint:loan-amount")}
            max={price}
            step={LOAN_AMOUNT_STEP}
            name="loan-amount"
            onChange={onChange}
            onValueSet={onValueSet}
        />
    );
}
