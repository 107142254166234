import moment from "moment";
import { calculatePaymentSteps } from "../functions/savings";
import type { IPaymentStep } from "../models/savings";
import type { IRootState } from "../reducers/root-reducer";

export function getPaymentSteps(state: IRootState): Array<IPaymentStep> {
    const { occupancyDate, price } = state.prospect;

    if (!occupancyDate) {
        return [];
    }

    return calculatePaymentSteps(occupancyDate, price);
}

export function getRequiredMonthlySavings(state: IRootState): number {
    const { paymentAmount, accountAmount, paymentDate } = state.savings;
    if (!paymentDate) return 0;

    const months = Math.max(Math.ceil(paymentDate.diff(moment(), "months", true)), 1);

    return Math.max((paymentAmount - accountAmount) / months, 0);
}
