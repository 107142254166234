import type { ILoan } from "./loan";
import type { IHousingParameters } from "./config";

export enum HousingTypes {
    House = "house",
    Condominium = "condominium",
    Rental = "rental",
    Lease = "lease",
}

export interface IHousing {
    type: HousingTypes;
    loans: Array<ILoan>;

    price: number;
    originalPrice?: number;
    isFixedPrice: boolean;
    fee?: number;
    maintenance?: number;
    mortgage: number;

    occupancyDate?: Date;

    renovationCost: number;

    name: string;
    year: number;
    imageUrl: string;

    calculateCapitalGainTax?: boolean;
}

export function createHousing(parameters: IHousingParameters, loans?: Array<ILoan>): IHousing {
    const price = parameters.estimatedPrice || 0;

    // TODO: REMOVE THIS CONVERTION BY IMPLEMENTING FULL SUPPORT FOR LEASE
    const isLease = parameters.type === HousingTypes.Lease;
    const type = isLease ? HousingTypes.House : parameters.type;

    return {
        type,
        loans,
        price,
        originalPrice: price,
        isFixedPrice: parameters.isFixedPrice !== null ? parameters.isFixedPrice : false,
        fee: type !== HousingTypes.House ? parameters.fee : null,
        maintenance: parameters.maintenance,
        mortgage: parameters.mortgage,
        occupancyDate: parameters.occupancyDate,
        renovationCost: 0,
        name: parameters.name,
        year: parameters.year,
        imageUrl: parameters.imageUrl,
        calculateCapitalGainTax: false,
    };
}
