import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { type IInsightModuleProps, insightsModule } from "../../../utils/module-store";
import { BANK_OPTIONS, type IBankInformation, type OnStringCallback } from "../../../utils/offer";
import { useTracking } from "react-tracking";
import { trackBankLead } from "../../../utils/analytics";
import { useSelector } from "react-redux";
import type { IRootState } from "../../../reducers/root-reducer";
import { shuffleArray } from "../../../utils/random";
import { AccessibleAnchor } from "../../accessibility/accessible-components";

const BankHighlight = (props: IInsightModuleProps) => {
    const { t } = useTranslation();
    const tracking = useTracking();
    const { prospect } = useSelector((state: IRootState) => {
        return {
            prospect: state.prospect,
        };
    });
    const description = (bank: IBankInformation) =>
        typeof bank.description === "string" ? bank.description : (bank.description as OnStringCallback)(prospect, bank);
    const url = (bank: IBankInformation) => (typeof bank.url === "string" ? bank.url : (bank.url as OnStringCallback)(prospect, bank));
    function onClick(id: string, url: string, copyText: string): void {
        tracking.trackEvent(trackBankLead({ action: id, copyText }));

        window.open(url, "_blank");
    }

    function merge(option): IBankInformation {
        const fromDefault = BANK_OPTIONS.find((x) => x.id === option.id);

        if (!fromDefault) {
            return option;
        }

        return {
            id: option.id,
            label: option.label || fromDefault.label,
            campaignId: option.campaignId || fromDefault.campaignId,
            description: option.description || fromDefault.description,
            url: option.url || fromDefault.url,
        };
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const bank = useMemo(() => merge(shuffleArray(props.configuration)[0]), [props.configuration]);

    return (
        <div className="bank-insight">
            <i className={classNames("logo", bank.id)} aria-label={bank.id} />
            <p>
                <small>{description(bank)}</small>
            </p>
            <AccessibleAnchor
                className="button"
                rel="sponsored"
                onClick={() => {
                    onClick(bank.id, url(bank), description(bank));
                }}
                role="link"
            >
                {t("offer:lead")} <i className="icon icon-externallink" />
            </AccessibleAnchor>
        </div>
    );
};

export default insightsModule("bank-highlight")(BankHighlight);
