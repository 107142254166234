import {
    EXISTING_HOUSING_FEE,
    EXISTING_HOUSING_HOUSE_MAINTAINANCE,
    EXISTING_HOUSING_NON_HOUSE_MAINTAINANCE,
    EXISTING_HOUSING_PRICE,
} from "../defaults";
import { createHousing, HousingTypes } from "../models/housing";
import { createExistingHousingLoan, createLoan } from "../models/loan";
import { clone, type IRootState } from "../reducers/root-reducer";

export function setExistingTypeFn(state: IRootState, action) {
    const newState = clone(state);

    const type = action.payload;
    newState.existing = createHousing({
        name: null,
        type,
        maintenance: type === HousingTypes.House ? EXISTING_HOUSING_HOUSE_MAINTAINANCE : EXISTING_HOUSING_NON_HOUSE_MAINTAINANCE,
        estimatedPrice: type === HousingTypes.Rental ? 0 : EXISTING_HOUSING_PRICE,
        fee: type === HousingTypes.House ? 0 : EXISTING_HOUSING_FEE,
        mortgage: 0,
    });

    newState.existing.loans = null;
    if (action.payload === HousingTypes.Rental) return newState;

    newState.existing.loans = [createExistingHousingLoan(newState.existing.price)];

    return newState;
}

export function setExistingFeeFn(state: IRootState, action) {
    const newState = clone(state);

    newState.existing.fee = action.payload;

    return newState;
}

export function setExistingMaintenanceFn(state: IRootState, action) {
    const newState = clone(state);

    newState.existing.maintenance = action.payload;

    return newState;
}

export function setExistingCalculateCapitalGainTaxFn(state: IRootState, action) {
    const newState = clone(state);

    newState.existing.calculateCapitalGainTax = action.payload;

    return newState;
}

export function setExistingOriginalPriceFn(state: IRootState, action) {
    const newState = clone(state);

    newState.existing.originalPrice = action.payload;

    return newState;
}

export function setExistingRenovationCostFn(state: IRootState, action) {
    const newState = clone(state);

    newState.existing.renovationCost = action.payload;

    return newState;
}

export function setExistingPriceFn(state: IRootState, action) {
    const newState = clone(state);

    newState.existing.price = action.payload;

    return newState;
}

export function setExistingLoanAmountFn(state: IRootState, action) {
    const newState = clone(state);

    const { index, value } = action.payload;

    newState.existing.loans[index].amount = value;

    return newState;
}

export function setExistingLoanInterestFn(state: IRootState, action) {
    const newState = clone(state);

    const { index, value } = action.payload;

    newState.existing.loans[index].interest = value;

    return newState;
}

export function setExistingLoanInstallmentFn(state: IRootState, action) {
    const newState = clone(state);

    const { index, value } = action.payload;

    newState.existing.loans[index].installment = value;

    return newState;
}

export function addExistingLoanFn(state: IRootState) {
    const newState = clone(state);

    newState.existing.loans.push(createLoan({ amount: 0, installment: 0 }));

    return newState;
}

export function removeExistingLoanFn(state: IRootState, action) {
    const newState = clone(state);

    newState.existing.loans.splice(action.payload, 1);

    return newState;
}
