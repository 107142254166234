import { clone, type IRootState } from "../reducers/root-reducer";

export function setSavingsPaymentDateFn(state: IRootState, action) {
    const newState = clone(state);

    newState.savings.paymentDate = action.payload.date;
    newState.savings.paymentAmount = action.payload.amount;

    return newState;
}

export function setSavingsPaymentAmountFn(state: IRootState, action) {
    const newState = clone(state);

    newState.savings.paymentAmount = action.payload;

    return newState;
}

export function setSavingsAccountAmountFn(state: IRootState, action) {
    const newState = clone(state);

    newState.savings.accountAmount = action.payload;

    return newState;
}
