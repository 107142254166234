import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { compareLoan } from "../../functions/housing";
import type { IRootState } from "../../reducers/root-reducer";
import { toCurrency } from "../../utils/format";

export default function LoanComparison() {
    const { t } = useTranslation();
    const { difference } = useSelector((state: IRootState) => {
        return {
            difference: compareLoan(state.prospect, state.existing),
        };
    });

    const direction = difference >= 0 ? t("compare:increase") : t("compare:decrease");

    const comparison = t("compare:result-comparison", {
        direction,
        difference: `<strong data-cy='compare-loan'>${difference > 0 ? "+" : "&#150"} ${toCurrency(Math.abs(difference))}</strong>`,
    });

    return (
        <>
            <h5>{t("compare:loan-header")}</h5>
            <p
                dangerouslySetInnerHTML={{
                    __html: comparison,
                }}
            />
        </>
    );
}
