import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { HousingTypes } from "../../models/housing";
import { type IRootState, setExistingType } from "../../reducers/root-reducer";
import Tabs, { Tab } from "../tabs-component";
import RentalFields from "./types/rental-fields-component";
import CondominiumFields from "./types/condominium-fields-component";
import HouseFields from "./types/house-fields-component";

export default function ExistingFieldsComponent() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { type } = useSelector((state: IRootState) => {
        return {
            type: state.existing.type,
        };
    });

    function onSetExistingType(type: HousingTypes) {
        dispatch(setExistingType(type));
    }

    return (
        <Tabs onClick={onSetExistingType} value={type}>
            <Tab title={t(`common:${HousingTypes.Rental}`)} value={HousingTypes.Rental} name={`select-${HousingTypes.Rental}`}>
                <RentalFields />
            </Tab>
            <Tab title={t(`common:${HousingTypes.Condominium}`)} value={HousingTypes.Condominium} name={`select-${HousingTypes.Condominium}`}>
                <CondominiumFields />
            </Tab>
            <Tab title={t(`common:${HousingTypes.House}`)} value={HousingTypes.House} name={`select-${HousingTypes.House}`}>
                <HouseFields />
            </Tab>
        </Tabs>
    );
}
