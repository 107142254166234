import * as React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import type { IRootState } from "../../reducers/root-reducer";
import { isRental } from "../../selectors/housings";
import CapitalComparison from "../comparisons/capital";
import LoanComparison from "../comparisons/loan";
import MonthlyCostComparison from "../comparisons/monthly-cost";
import HintComponent from "../hint-component";

export default function CompareComponent() {
    const { t } = useTranslation();
    const { rental } = useSelector((state: IRootState) => {
        return {
            rental: isRental(state.existing),
        };
    });

    return (
        <div>
            <div>
                <h4>
                    {t("compare:header")} <HintComponent>{t("hint:compare")}</HintComponent>
                </h4>
            </div>
            <div className="row">
                <div className="container fill shrink">
                    <MonthlyCostComparison />
                </div>
                {!rental && (
                    <div className="container fill shrink">
                        <CapitalComparison />
                    </div>
                )}
                {!rental && (
                    <div className="container fill shrink">
                        <LoanComparison />
                    </div>
                )}
            </div>
        </div>
    );
}
