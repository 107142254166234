import * as React from "react";
import Maintenance from "../fields/maintenance-field";
import Fee from "../fields/fee-field";
import Price from "../fields/price-field";
import Valuation from "../valuation-component";
import CapitalGainTax from "../fields/capital-gain-tax-field";
import Loans from "../fields/loans-field";

export default function CondominiumFieldsComponent() {
    return (
        <div className="row">
            <div className="hint-container container">
                <Price />
                <Valuation />
            </div>
            <div className="hint-container container outline">
                <Loans />
            </div>
            <div className="hint-container container">
                <Fee />
                <Maintenance />
                <CapitalGainTax />
            </div>
        </div>
    );
}
