import * as React from "react";

import { useTracking } from "react-tracking";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { type IRootState, setExistingPrice } from "../../../reducers/root-reducer";
import NumberComponent from "../../form-controls/number-component";
import { trackEngagement, UserInput } from "../../../utils/analytics";
import { PRICE_MIN, PRICE_MAX, PRICE_STEP } from "../../../defaults";

export default function PriceField() {
    const { t } = useTranslation();
    const tracking = useTracking();
    const dispatch = useDispatch();

    const { price, type } = useSelector((state: IRootState) => {
        return {
            price: state.existing.price,
            type: state.prospect.type,
        };
    });

    function onChange(value: number) {
        dispatch(setExistingPrice(value));
    }

    function onValueSet(value: number) {
        tracking.trackEvent(
            trackEngagement({
                action: UserInput.Existing_Price,
                value: value.toString(),
                housingType: type,
            }),
        );
    }

    return (
        <NumberComponent
            label={t("existing:price")}
            unit="kr"
            ariaLabel={t("common:currency")}
            value={price}
            min={PRICE_MIN}
            max={PRICE_MAX}
            step={PRICE_STEP}
            name="existing[price]"
            onChange={onChange}
            onValueSet={onValueSet}
        />
    );
}
