import moment from "moment";
import { PaymentSteps } from "../models/savings";

// TODO: This could be more dynamic with configuration.
export function calculatePaymentSteps(occupancy: Date, price: number) {
    const currentDate = moment().startOf("day");
    const occupancyDate = moment(occupancy).startOf("day");
    const monthsUntilOccupancy = occupancyDate.diff(currentDate, "months");

    if (monthsUntilOccupancy > 6) {
        const upplatelseavtalDate = moment(occupancyDate).subtract(6, "months");

        return [
            {
                id: PaymentSteps.AdvanceAgreement,
                date: currentDate,
                amount: price * 0.025,
                percentage: 2.5,
            },
            {
                id: PaymentSteps.LeaseAgreement,
                date: upplatelseavtalDate,
                amount: price * 0.075,
                percentage: 7.5,
            },
            {
                id: PaymentSteps.Entry,
                date: occupancyDate,
                amount: price * 0.9,
                percentage: 90,
            },
        ];
    }

    const occupancyOrCurrentDate = occupancyDate.isBefore(currentDate) ? currentDate : occupancyDate;

    return [
        {
            id: PaymentSteps.LeaseAgreement,
            date: currentDate,
            amount: price * 0.1,
            percentage: 10,
        },
        {
            id: PaymentSteps.Entry,
            date: occupancyOrCurrentDate,
            amount: price * 0.9,
            percentage: 90,
        },
    ];
}
