import * as React from "react";

import { useTracking } from "react-tracking";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { type IRootState, setExistingMaintenance } from "../../../reducers/root-reducer";
import NumberComponent from "../../form-controls/number-component";
import { trackEngagement, UserInput } from "../../../utils/analytics";
import { MAINTENANCE_STEP, MAINTENANCE_MAX } from "../../../defaults";

export default function MaintenanceField() {
    const { t } = useTranslation();
    const tracking = useTracking();
    const dispatch = useDispatch();

    const { maintenance, type } = useSelector((state: IRootState) => {
        return {
            maintenance: state.existing.maintenance,
            type: state.prospect.type,
        };
    });

    function onChange(value: number) {
        dispatch(setExistingMaintenance(value));
    }

    function onValueSet(value: number) {
        tracking.trackEvent(
            trackEngagement({
                action: UserInput.Existing_Maintenance,
                value: value.toString(),
                housingType: type,
            }),
        );
    }

    return (
        <NumberComponent
            label={t("existing:maintenance")}
            unit="kr"
            ariaLabel={t("common:currency")}
            hint={t(`hint:maintenance-${type}`)}
            value={maintenance}
            max={MAINTENANCE_MAX}
            step={MAINTENANCE_STEP}
            name="existing[maintenance]"
            onChange={onChange}
            onValueSet={onValueSet}
        />
    );
}
