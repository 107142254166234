import { DEDUCTION_THRESHOLD, BASE_DEDUCTION_RATE, EXTRA_DEDUCTION_RATE, MONTHS_IN_YEAR } from "../defaults";
import type { IHousing } from "../models/housing";
import { minInstallment } from "../functions/installment";
import type { ILoan } from "../models/loan";
import { getTotalLoanAmount } from "../selectors/loans";
import { calculateHousingCapital, calculateMinimumCapital } from "./housing";

export function calculateNewLoanAmount(housing: IHousing, newPrice: number, isProspect = true): number {
    const currentCapital = calculateHousingCapital(housing);
    const adjustedCapital = adjustCapital(newPrice, currentCapital, isProspect);
    return newPrice - adjustedCapital;
}

function adjustCapital(price: number, currentCapital: number, isProspect = true): number {
    // capital should not be greater than housing price
    const capitalAfterAdjustingForPrice = Math.min(currentCapital, price);
    const minCapital = calculateMinimumCapital(price, isProspect);
    // capital should not be lower than the minimun capital requirement
    const adjustedCapital = Math.max(minCapital, capitalAfterAdjustingForPrice);

    return adjustedCapital;
}

export function updateHousingLoans(housing: IHousing, totalLoanAmount: number): IHousing {
    const { loans } = housing;

    if (loans.length) {
        const amountPerPerLoan = totalLoanAmount / loans.length;
        for (const loan of loans) {
            loan.amount = amountPerPerLoan;
        }
    }

    return housing;
}

export function updateMinInstallment(housing: IHousing, income: number): IHousing {
    if (!housing.loans.length) return housing;

    const newMinInstallment = minInstallment(housing.price, getTotalLoanAmount(housing), income);

    housing.loans[0].installment = newMinInstallment;

    return housing;
}

export function monthlyInterestCostAfterDeduction(loans: Array<ILoan>): number {
    if (loans === null) return 0;

    const yearlyBaseInterest = loans.reduce((n, { interest, amount }) => n + interest * amount, 0);

    const interestDeduction = deduction(yearlyBaseInterest);
    const yearlyInterest = yearlyBaseInterest - interestDeduction;
    const montlyInterest = yearlyInterest / MONTHS_IN_YEAR;

    return Math.round(montlyInterest);
}

function deduction(baseInterestCost: number): number {
    if (baseInterestCost <= DEDUCTION_THRESHOLD) {
        return baseInterestCost * BASE_DEDUCTION_RATE;
    }

    const baseInterestDeduction = DEDUCTION_THRESHOLD * BASE_DEDUCTION_RATE;
    const extraInterestDeduction = (baseInterestCost - DEDUCTION_THRESHOLD) * EXTRA_DEDUCTION_RATE;

    return baseInterestDeduction + extraInterestDeduction;
}

export function forecastLoan(loan: ILoan, years: number): ILoan {
    let { amount, installment } = loan;

    for (let i = 0; i < years; ++i) {
        const totalInstallmentsForYear = installment * MONTHS_IN_YEAR;

        if (i > 0) {
            amount = Math.max(amount - totalInstallmentsForYear, 0);
        }

        if (totalInstallmentsForYear > amount) {
            installment = Math.ceil(amount / MONTHS_IN_YEAR);
        }
    }

    return { ...loan, amount, installment };
}
