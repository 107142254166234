export interface IInsightModuleProps {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    configuration?: any;
}

export interface IInsightsModule {
    id: string;
    configuration?: IInsightModuleProps;
    component: React.FunctionComponent<IInsightModuleProps> | React.ComponentClass<IInsightModuleProps>;
}

export class ModuleStore {
    public static insightsModules: {
        [id: string]: IInsightsModule;
    } = {};

    public static getInsightsModule(id: string): IInsightsModule {
        return ModuleStore.insightsModules[id];
    }
}

export function insightsModule(id: string) {
    return (component: React.FunctionComponent<IInsightModuleProps> | React.ComponentClass<IInsightModuleProps>) => {
        ModuleStore.insightsModules[id] = {
            id,
            component,
        };
        return component;
    };
}
